<template>
	<div class="content">
		<div class="custom-modal-mask">
			<notifications width="400px" group="input" />
			<div class="custom-modal-wrapper">
				<div class="custom-modal-container">
					<div class="custom-modal-header">
						<h1>Lead-Funnel Backend</h1>
					</div>
					<div class="custom-modal-body">
						<h3>Passwort eingeben</h3>
						<input
							v-model="password"
							@keyup.enter="checkPassword"
							type="password"
						/>
					</div>
					<div class="custom-modal-footer">
						<button @click="checkPassword" class="ok">Einloggen</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
	name: 'Login',
	data: function () {
		return {
			password: ''
		};
	},
	methods: {
		...mapActions(['login']),
		checkPassword: async function () {
			if (await this.login(this.password)) {
				this.$router.push({ name: 'Projekte' });

				this.$notify({
					group: 'input',
					type: 'success',
					title: 'Login erfolgreich',
					text: 'Sie sind nun eingeloggt!'
				});
			} else
				this.$notify({
					group: 'input',
					type: 'error',
					title: 'Fehler beim Login',
					text: 'Das Passwort ist falsch!'
				});
		}
	}
};
</script>

<style lang="scss" scoped>
@import '@/scss/modal_base.scss';

.content {
	display: block;

	position: absolute;
	height: auto;

	bottom: 0;
	top: 0;
	left: 0;
	right: 0;

	background-color: $unnamed-color-000000;
	overflow: auto;

	.custom-modal-header {
		margin-bottom: 40px;

		h1 {
			@include normal-text;
			font-size: 22px;
			color: $unnamed-color-999999;
			text-transform: uppercase;
			font-weight: bold;
		}
	}

	.custom-modal-body {
		margin-bottom: 40px;

		h3 {
			margin-top: 0;
			margin-bottom: 8px;

			@include normal-text;
			color: $unnamed-color-999999;
			font-weight: normal;
			font-size: 22px;
		}

		input {
			width: 100%;
			height: 48px;

			padding: 0 16px;

			border: 1px solid $unnamed-color-999999;
			background: #262626 0% 0% no-repeat padding-box;
			border: 1px solid #999999;
			border-radius: 4px;
			opacity: 1;

			-webkit-box-sizing: border-box;
			-moz-box-sizing: border-box;
			box-sizing: border-box;

			@include normal-text;
			font-size: 22px;
			color: white;
		}
	}

	.custom-modal-footer {
		button {
			display: block;
			width: 100%;
			height: 48px;

			@include normal-text;
			font-size: 22px;
			font-weight: bold;
			text-transform: uppercase;

			margin-bottom: 5px;

			&.ok {
				border: none;
				background: $unnamed-color-fdc300 0% 0% no-repeat padding-box;
				background: #fdc300 0% 0% no-repeat padding-box;
				border-radius: 4px;
				opacity: 1;

				cursor: pointer;
				color: $unnamed-color-000000;
			}

			&.cancel {
				border: none;
				background: $unnamed-color-333333 0% 0% no-repeat padding-box;
				background: #333333 0% 0% no-repeat padding-box;
				border-radius: 4px;
				opacity: 1;

				cursor: pointer;
				color: $unnamed-color-999999;
			}
		}
	}
}
</style>
